import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import AppAction from "../../../stores/app/AppAction";
import Translate from "../../../utilities/etc/Translate";
import {useAppSelector} from "../../../stores/hooks";
import {ProductOverview} from "../../_components/ProductOverview";

const mapStateToProps = (state) => ({
    ...state.app,
    shoppingCart: state.shoppingCart,
    rental: state.rental.rentalData.update,
    paymentProvider: state.payment.paymentMethods.provider,
});

const Products = (props) => {

    const {infoData} = useAppSelector(state => state.info)
    const shopIsTypeRecurring = !!infoData.detail.webshops?.webshop?.recurring_enabled ?? false

    if (props.rental && props.webshop.type.code !== 'registration') {

        let discount = 0;

        if (!!props.periodDiscount) {
            discount = props.periodDiscount
        } else {

            if (props.webshop.default_discount && props.webshop.discount_percentage > 0) {
                discount = props.webshop.discount_percentage
            } else {
                if (props.delivery.deliveryPickupLocation && props.delivery.deliveryPickupLocation.address && props.delivery.deliveryPickupLocation.address.discount_percentage) {
                    discount = props.delivery.deliveryPickupLocation.address.discount_percentage
                }
            }
        }

        return (
            <div className="c-order-overview__products">

                <header>
                    <h3>{Translate('Uw *item_plural* & accessoires', props.language)}</h3>
                    <button onClick={() => props.dispatch(AppAction.switchAppStep(RouteEnum.Bicycles))}
                            className="btn c-order-overview__edit">{Translate('Wijzigen', props.language)}
                    </button>
                </header>

                <ProductOverview
                    rentalItems={props.rental.rentalItems}
                    shopIsTypeRecurring={shopIsTypeRecurring}
                    discount={discount}
                    deferredPaymentEnabled={false}
                    language={props.language}
                    paymentProvider={props.paymentProvider}
                    rental={props.rental}
                />

            </div>
        )
    } else return null


}

export default connect(mapStateToProps)(Products);
