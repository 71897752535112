import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../../stores/app/AppAction";
import ChangeAccessoireNumber from "../../../utilities/dispatchers/ChangeAccessoireNumber";
import DisplayItemName from "../../../utilities/displayers/DisplayItemName";
import DisplayPrice from "../../../utilities/displayers/DIsplayPrice";
import Translate from "../../../utilities/etc/Translate";
import DisplayItemAvailability from "../../../utilities/displayers/DisplayItemAvailability";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

const mapStateToProps = (state) => ({
    ...state.app,
    info: state.info
});

class AccessoirePopup extends React.Component {


    render() {

        const popup = this.props.popup.accessoire
        const data = this.props.accessoires[popup.id]

        return (
            <div className="c-modal">
                <div className="c-modal__bg"/>
                <div className="c-modal__content c-modal__content--product">
                    <button className="c-modal__close" onClick={() => this._onClickClosePopup()}><i
                        className="icon icon-cross"/></button>

                    {!!data.images &&
                    <div className="c-modal__product__media c-bicycle-popup-carousel">
                        <Carousel dynamicHeight={false} swipeable={true} showArrows={true} showThumbs={false}
                                  showStatus={false} infiniteLoop={true}>
                            {data.images.map((image) => (
                                <img src={image}/>
                            ))}
                        </Carousel>
                    </div>
                    }

                    <div className="c-modal__product__info">
                        <h2>
                            {DisplayItemName(data)}
                        </h2>
                        <p>
                            {data.description}
                        </p>
                        <div className="c-modal__product__info__amount">
                            <div className="c-products__single__amount">
                                <button className="minus" onClick={() => {
                                    ChangeAccessoireNumber(data, -1, this.props.dispatch)
                                }}><i className="icon icon-minus"/></button>
                                <span>{data.selected}</span>
                                <button className="plus" onClick={() => {
                                    ChangeAccessoireNumber(data, 1, this.props.dispatch)
                                }}><i className="icon icon-plus"/></button>
                            </div>
                            <span className="c-products__single__price">{DisplayPrice(data.price)}
                                <small>{Translate(this.props.webshop.recurring_enabled ? 'p/s / p/m' : 'p/s', this.props.language)}</small>
                            </span>
                        </div>

                        {data.locations &&
                            <div className="c-products__single__locations">

                            {Object.keys(data.locations).map((locationId) => {

                                const location = this.props.locations[locationId]
                                location.accessoireData = data.locations[locationId]

                                const availability = DisplayItemAvailability(location.accessoireData.available)

                                let className = 'c-products__single__locations__single'

                                if (location.excluded || availability) {

                                    if (!this.props.webshop.blur_locations) {
                                        return null;
                                    }

                                    className += ' is-deactivated'
                                }

                                return (
                                    <div className={className}>
                                        <div className="c-products__single__locations__single__wrapper">
                                            <button className=""><i className="icon icon-pin"/></button>
                                            <div className="c-products__single__locations__single__name">
                                                <span>{location.name}</span>
                                            </div>
                                        </div>
                                        {this.props.webshop.client_id !== 18 &&
                                            <div className="c-products__single__locations__single__amount">

                                                {!!this.props.info?.infoData?.detail?.webshops?.item_icon &&
                                                <img style={{width: '2.5rem', marginRight: '0.5rem'}}
                                                     src={this.props.info?.infoData?.detail?.webshops?.item_icon}/>
                                                }

                                                {!this.props.info?.infoData?.detail?.webshops?.item_icon &&
                                                <i className="icon icon-bicycle"/>
                                                }

                                                <small>{availability}</small>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    _onClickClosePopup() {
        this.props.dispatch(AppAction.changeAccessoirePopup({
            show: false,
            id: false
        }))
    }

}

export default connect(mapStateToProps)(AccessoirePopup);
