import Translate from "../../utilities/etc/Translate";
import DisplayItemName from "../../utilities/displayers/DisplayItemName";
import DisplayPrice from "../../utilities/displayers/DIsplayPrice";
import PaymentOptions from "../PaymentOverview/_components/PaymentOptions";
import DisplayDecimal from "../../utilities/displayers/DisplayDecimal";
import React from "react";

export const ProductOverview = (props) => {

    const {
        rentalItems,
        shopIsTypeRecurring,
        discount,
        deferredPaymentEnabled,
        language,
        paymentProvider,
        rental
    } = props

    const insuranceIcon = '/images/insurance-icon.svg'
    const deliveryIcon = '/images/tilia-truck-square-black.svg'
    const pickupIcon = '/images/tilia-return-truck-square-black.svg'
    const serviceIcon = '/images/service-icon.svg'
    const depositIcon = '/images/deposit-icon.svg'

    const depositAndDeliveryItems = shopIsTypeRecurring
        ? rentalItems.filter(item => item.type === 'deposit' || item.type === 'delivery')
        : [];

    const otherItems = shopIsTypeRecurring
        ? rentalItems.filter(item => item.type !== 'deposit' && item.type !== 'delivery')
        : rentalItems;

    return (
        <div className="c-order-overview__products__container">
            {otherItems && otherItems
                .sort((a, b) => a.type === 'service' ? 1 : b.type === 'service' ? -1 : 0)
                .sort((a, b) => a.type === 'deposit' ? 1 : b.type === 'deposit' ? -1 : 0)
                .map((item) => {

                    if (item.type === 'category'
                        || item.type === 'insurance'
                        || item.type === 'delivery'
                        || item.type === 'pick_up'
                        || item.type === 'deposit'
                        || item.type === 'service'
                    ) {

                        const categoryData = {
                            ...item.category
                        }

                        let itemImage = null;

                        switch (item.type) {
                            case 'category':
                                itemImage = categoryData.image
                                break;
                            case 'insurance':
                                itemImage = insuranceIcon
                                break;
                            case 'delivery':
                                itemImage = deliveryIcon
                                categoryData.name = Translate('Bezorging', language)
                                break;
                            case 'pick_up':
                                itemImage = pickupIcon
                                categoryData.name = Translate('Ophalen', language)
                                break;
                            case 'deposit':
                                itemImage = depositIcon
                                categoryData.alternative_label = item.description
                                break;
                            case 'service':
                                itemImage = serviceIcon
                                categoryData.name = item.description
                                break;
                            default:
                                break;
                        }

                        return (
                            <div key={'rental-item-' + item.id} className="c-order-overview__products__single">
                                <div className="c-order-overview__products__single__container">
                                    {!!itemImage && <div className="c-order-overview__products__single__media">
                                        <img src={itemImage} alt=""/>
                                    </div>}
                                    <span
                                        className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                    <span
                                        className="c-order-overview__products__single__description">{DisplayItemName(categoryData)}</span>
                                </div>
                                <span
                                    className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>

                            </div>
                        )
                    }
                })}

                {depositAndDeliveryItems.length > 0 && (
                    <div className="c-order-overview__products__group">
                        <h3>{Translate('Borg en Bezorgkosten', language)}</h3>
                        <div style={{borderTop: '1px solid #DEE5F0', margin: '2rem 0'}}/>
                        {depositAndDeliveryItems.map((item) => {
                            let itemImage = item.type === 'deposit' ? depositIcon : deliveryIcon;
                            let itemName = item.type === 'deposit' ? item.description : Translate('Bezorging', language);

                            return (
                                <div key={'rental-item-' + item.id} className="c-order-overview__products__single">
                                    <div className="c-order-overview__products__single__container">
                                        <div className="c-order-overview__products__single__media">
                                            <img src={itemImage} alt=""/>
                                        </div>
                                        <span className="c-order-overview__products__single__amount">{item.quantity}x</span>
                                        <span className="c-order-overview__products__single__description">{itemName}</span>
                                    </div>
                                    <span className="c-order-overview__products__single__price">{DisplayPrice(item.totalPrice)}</span>
                                </div>
                            );
                        })}
                    </div>
                )}

            <div style={{borderTop: '1px solid #DEE5F0', margin: '2rem 0'}}/>

            {paymentProvider === 'ccv' || (shopIsTypeRecurring && paymentProvider === 'pay')
                ? <PaymentOptions/>
                : <></>
            }

            {!deferredPaymentEnabled &&
                <div className="c-order-overview__total">
                    <span>{Translate('Totaalbedrag (incl. 21% BTW)', language)}</span>
                    <span>{DisplayPrice(rental.subTotalPrice)}</span>
                </div>
            }

            {!deferredPaymentEnabled && !!rental.totalDiscount &&
                <div style={{paddingTop: 0}} className="c-order-overview__total">
                    <span>{DisplayDecimal(discount)}% {Translate('Korting', language)}</span>
                    <span>{DisplayPrice(rental.totalDiscount)}</span>
                </div>
            }

            {!deferredPaymentEnabled &&
                <div style={{paddingTop: 0}} className="c-order-overview__total">
                    <span>{Translate('Totaal te betalen', language)}</span>
                    <span>{DisplayPrice(rental.totalPrice)}</span>
                </div>
            }

        </div>
    )

}